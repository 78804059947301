import React from "react";

import './projects.css'

import APP_STORE_BADGE from '../../assets/images/App Store Badge.svg'
import GOOGLE_STORE_BADGE from '../../assets/images/Google Store Badge.svg'

import SD_ICON from '../../assets/images/stranger-danger-icon.png'
import SD_MOCKUP from '../../assets/images/stranger-danger-mockup.png'

import LGTB_ICON from '../../assets/images/lgtb-icon.png'
import LGTB_MOCKUP from '../../assets/images/lgtb-mockup.png'

export const ProjectsScreen = () => {

    return (
        <div className={'pt-24 px-8 md:px-24 pb-0'}>
            <div className={'md:flex'}>
                <div className={'content-container md:flex-1'}>
                    <h1 className={'text-lg font-bold text-black mb-8'}><span className={'underline decoration-black'} style={{textUnderlineOffset: 16, textDecorationThickness: 16}}>Stranger</span> Danger</h1>
                    <p className={'font-bold text-base text-lightGray'}>A new social card game powered by mobile and AI for endless entertainment. Whether you're looking to start a conversation, want to break the ice with a new acquaintance, or just looking for something to do with your friends, Stranger Danger brings ai-entertainment to a new level.</p>
                    <p className={'mt-8 font-bold text-base text-lightGray'}>Visit <a className={'text-black'} href={'https://strangerdanger.app'}>StrangerDanger.app</a> for more.</p>
                    <div className={'mt-16'}>
                        <img className={'float-left mb-8 mr-8 w-16 h-16 md:w-32 md:h-32'} src={SD_ICON} alt="App icon"/>
                        <div className={'float-left'}>
                            <a href="https://apps.apple.com/us/app/stranger-danger-game/id1571534215">
                                <img className={'mb-2 w-48 h-16'} src={APP_STORE_BADGE} alt="Download on the App Store"/>
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=com.strangerdanger">
                                <img className={'mt-2 w-48 h-16'} src={GOOGLE_STORE_BADGE} alt="Get it on Google Play"/>
                            </a>
                        </div>
                    </div>

                </div>
                <div className={'content-container md:flex-1 py-24'}>
                    <div className={'h-full w-full'}>
                        <img className={'w-full h-full object-contain'} src={SD_MOCKUP} alt="Mockup"/>
                    </div>
                </div>
            </div>

            <div className={'md:flex'}>
                <div className={'content-container md:flex-1'}>
                    <h1 className={'text-lg font-bold text-black mb-8'}>Let's <span className={'underline decoration-black'} style={{textUnderlineOffset: 16, textDecorationThickness: 16}}>get</span> this <span className={'underline decoration-black'} style={{textUnderlineOffset: 16, textDecorationThickness: 16}}>bread</span></h1>
                    <p className={'font-bold text-base text-lightGray'}>A constant source of motivation brought to you fresh every day!</p>
                    <p className={'mt-8 font-bold text-base text-black'}>Coming soon...</p>
                    <div className={'mt-16'}>
                        <img className={'float-left mb-8 mr-8 w-16 h-16 md:w-32 md:h-32'} src={LGTB_ICON} alt="App icon"/>
                        <div className={'float-left'}>
                            <img className={'mb-2 w-48 h-16'} src={APP_STORE_BADGE} alt="Download on the App Store"/>
                            <img className={'mt-2 w-48 h-16'} src={GOOGLE_STORE_BADGE} alt="Get it on Google Play"/>
                        </div>
                    </div>

                </div>
                <div className={'content-container md:flex-1 py-24'}>
                    <div className={'h-full w-full'}>
                        <img className={'w-full h-full object-contain'} src={LGTB_MOCKUP} alt="Mockup"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
