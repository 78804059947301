import React from 'react';
import './App.css';

import {Navbar} from "./components/atoms/Navbar";
import {
    Routes,
    Route,
} from "react-router-dom";
import {HomeScreen} from "./scenes/home";
import {ProjectsScreen} from "./scenes/projects";
import {PrivacyScreen} from "./scenes/privacy";
import {StrangerDangerEulaScreen} from "./scenes/strangerDanger/eula";

function App() {
  return (
    <div className="App">
        <Navbar />
        <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/projects" element={<ProjectsScreen />} />
            <Route path="/privacy" element={<PrivacyScreen />} />
            <Route path="/strangerdanger/eula" element={<StrangerDangerEulaScreen />} />

        </Routes>
    </div>
  );
}

export default App;
