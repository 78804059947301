import React from "react";
import './home.css'

export const HomeScreen = () => {
    return (
        <div className={'flex flex-1 justify-center content-container items-center'}>
            <h1 className={'text-title font-bold text-black text-center'}>Yo Dawg <span className={'text-blue underline decoration-32 decoration-black underline-offset-8'}>Brand</span></h1>
        </div>
    )
}
