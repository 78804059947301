import {Link, useLocation} from "react-router-dom";
import classNames from "classnames";


export const Navbar = () => {
    const {pathname} = useLocation();

    return (
        <div className={'fixed inset-x-0 bg-white'}>
            <div className="flex items-center justify-center">
                <Link className={classNames('text-base text-black font-bold m-8', pathname === '/' && 'underline decoration-8 underline-offset-8')} to={'/'}>Home</Link>
                <Link className={classNames('text-base text-black font-bold m-8', pathname === '/projects' && 'underline decoration-8 underline-offset-8')} to={'/projects'}>Projects</Link>
            </div>
        </div>
    )
}
